module.exports = {
  siteTitle: "kirkanos", // <title>
  shortSiteTitle: "kirkanos", // <title> ending for posts and pages
  siteDescription: "Blog for everything.",
  siteUrl: "https://www.kirkanos.net",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "de",

  /* author */
  authorName: "kirkanos",
  authorTwitterAccount: "kirkanos_ah",
  authorFacebookAccount: "https://www.facebook.com/ahacker83",
  authorEmail: "kirkanos@wbg-clan.de",
  authorLastfmAccount: "wbgkirkanos",
  authorInstagramAccount: "ahacker83",

  /* info */
  headerTitle: "kirkanos.net",
  headerSubTitle: "... man kann's kaum glauben ...",

  /* manifest.json */
  manifestName: "kirkanos.net",
  manifestShortName: "kirkanos", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "https://www.gravatar.com/avatar/1846013caf3710a2bdf23e87322aaf6c",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/greglobinski" },
    { name: "twitter", url: "https://twitter.com/kirkanos_ah" }
  ]
};
