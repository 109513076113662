module.exports = [{
      plugin: require('/var/lib/gitlab-runner/builds/42a6330d/0/kirkanos/kirkanos.net/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/var/lib/gitlab-runner/builds/42a6330d/0/kirkanos/kirkanos.net/src/layouts/index.js"},
    },{
      plugin: require('/var/lib/gitlab-runner/builds/42a6330d/0/kirkanos/kirkanos.net/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/gitlab-runner/builds/42a6330d/0/kirkanos/kirkanos.net/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/gitlab-runner/builds/42a6330d/0/kirkanos/kirkanos.net/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134418041-1"},
    },{
      plugin: require('/var/lib/gitlab-runner/builds/42a6330d/0/kirkanos/kirkanos.net/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
